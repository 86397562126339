import { AutoSuggestTextInput } from '@toasttab/buffet-pui-lookup'
import { useCombobox } from 'downshift'
import { NumberInput } from '@toasttab/buffet-pui-text-input'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { SubtractCircleIcon } from '@toasttab/buffet-pui-icons'
import * as React from 'react'
import { IShardRep } from '@local/shard-brain-client'

export function EditAttributeShardRow({
  shard,
  restriction,
  setShard,
  setRestriction,
  remove,
  shardList,
  showLabel,
  errorMessage
}: {
  shard: string
  restriction: number
  setShard: (s: string) => void
  setRestriction: (r: string) => void
  remove: () => void
  shardList: Array<IShardRep> | undefined
  showLabel: boolean
  errorMessage: string
}) {
  const items = (shardList || []).map((s) => s.guid)
  const comboboxInstance: any = useCombobox({
    items: items,
    onInputValueChange: ({ inputValue }) => {
      setShard(inputValue || '')
    },
    onSelectedItemChange: () => comboboxInstance.closeMenu()
  })
  return (
    <div>
      <div className='flex flex-row items-end gap-4'>
        <AutoSuggestTextInput
          label={showLabel ? 'Shard' : undefined}
          renderItem={(value: string | null) => {
            const foundShard = shardList?.find((s) => s.guid === value)
            if (foundShard) {
              return (
                <p>
                  <span className='block'>{foundShard.name}</span>
                  <span className='type-overline font-thin text-secondary'>
                    {value}
                  </span>
                </p>
              )
            } else {
              return <span>value</span>
            }
          }}
          items={items}
          value={shard}
          onFocus={() => {
            comboboxInstance.openMenu()
          }}
          comboboxInstance={comboboxInstance}
          suffixVariant='iconButton'
        />
        <NumberInput
          label={showLabel ? 'Restriction' : undefined}
          suffix='%'
          containerClassName='w-22'
          onChange={(e) => setRestriction(e.value)}
          value={restriction}
        />
        <IconButton icon={<SubtractCircleIcon />} onClick={() => remove()} />
      </div>
      <div>
        <span style={{ color: 'red' }}>{errorMessage}</span>
      </div>
    </div>
  )
}
