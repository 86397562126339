import {
  IFlowControlPolicy,
  IPolicyCreationRequest,
  IPolicyDeletionRequest,
  IPolicyUpdateRequest
} from '@local/interfaces'

/**
 * PolicyApi
 * API for policy objects
 */
export interface IPolicyApi {
  getPolicies: () => Promise<Array<IFlowControlPolicy>>
  updatePolicy: (policyUpdateRequest: IPolicyUpdateRequest) => Promise<void>
  deletePolicy: (policyDeletionRequest: IPolicyDeletionRequest) => Promise<void>
  createPolicy: (policy: IPolicyCreationRequest) => Promise<void>
}
export class PolicyApi implements IPolicyApi {
  private baseUrl: string
  private accountName: string

  constructor(baseUrl: string, accountName: string) {
    this.baseUrl = baseUrl
    this.accountName = accountName
  }

  public getPolicies = async (): Promise<Array<IFlowControlPolicy>> => {
    console.log('fetching policies...')
    const url =
      this.baseUrl + '/v1/ird/' + this.accountName + '/policies/GATEWAY'
    const res = await fetch(url)
    const responseBody = await res.json()
    if (res.status > 299) {
      throw new Error(responseBody.message)
    }
    return responseBody
  }

  public updatePolicy = async (
    policyUpdateRequest: IPolicyUpdateRequest
  ): Promise<void> => {
    const url =
      this.baseUrl +
      '/v1/ird/' +
      this.accountName +
      '/policies/GATEWAY/' +
      policyUpdateRequest.target

    const requestOptions = {
      method: 'PUT',
      body: JSON.stringify(policyUpdateRequest),
      headers: { 'Content-Type': 'application/json' }
    }
    const res = await fetch(url, requestOptions)
    console.log(res)
    const responseBody = await res.json()
    if (res.status > 299) {
      throw new Error(responseBody.message)
    }
  }

  public deletePolicy = async (
    policyDeletionRequest: IPolicyDeletionRequest
  ): Promise<void> => {
    const url =
      this.baseUrl +
      '/v1/ird/' +
      this.accountName +
      '/policies/GATEWAY/' +
      policyDeletionRequest.target

    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const res = await fetch(url, requestOptions)
    console.log(res)
    if (res.status > 299) {
      const responseBody = await res.json()
      throw new Error(responseBody.message)
    }
  }

  public createPolicy = async (
    policy: IPolicyCreationRequest
  ): Promise<void> => {
    const url = this.baseUrl + '/v1/ird/' + this.accountName + '/policies'

    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(policy),
      headers: { 'Content-Type': 'application/json' }
    }
    const res = await fetch(url, requestOptions)
    console.log(policy)
    const responseBody = await res.json()
    console.log(responseBody)
    if (res.status > 299) {
      throw new Error(responseBody.message)
    }
  }
}
