import { IFlowControlSalt } from '@local/interfaces'

/**
 * SaltApi
 * API to create, update, and read a Salt
 */
export interface ISaltApi {
  getSalt: () => Promise<IFlowControlSalt>
  createSalt: () => Promise<void>
}
export class SaltApi implements ISaltApi {
  private baseUrl: string
  private accountName: string

  constructor(baseUrl: string, accountName: string) {
    this.baseUrl = baseUrl
    this.accountName = accountName
  }

  public getSalt = async (): Promise<IFlowControlSalt> => {
    console.log('Fetching the salt...')
    const url = this.baseUrl + '/v1/ird/' + this.accountName + '/salt'
    const res = await fetch(url)
    const responseBody = await res.json()
    console.log(responseBody)
    if (res.status > 299 && res.status !== 404) {
      throw new Error(responseBody.message)
    }
    return responseBody
  }

  public createSalt = async (): Promise<void> => {
    console.log('Creating/updating a salt...')
    const url = this.baseUrl + '/v1/ird/' + this.accountName + '/salt'
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
    const res = await fetch(url, requestOptions)
    const responseBody = await res.json()
    if (res.status > 299) {
      throw new Error(responseBody.message)
    }
  }
}
