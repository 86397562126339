import {Tooltip} from "@toasttab/buffet-pui-tooltip";
import * as React from "react";

/**
 * TargetedShard component
 * nested row to show the restriction percentage for a shard
 */
export function TargetedShardDetail({shardGuid,shardName,restrictionPercentage}:{shardGuid:string,shardName:string|undefined,restrictionPercentage:number}) {
    return <div className='flex flex-row justify-between' key={shardGuid}>
        {shardName
            ? <Tooltip content={() => shardGuid}><span>{shardName} : </span></Tooltip>
            : <span>{shardGuid}</span> }
        <span data-testid={`shard-${shardGuid}`}>{restrictionPercentage} %</span>
    </div>
}
