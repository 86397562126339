export interface IShardRep {
  name: string
  guid: string
}
export interface IShardBrainApi {
  listShards: () => Promise<Array<IShardRep>>
}
export class ShardBrainApi implements IShardBrainApi {
  private baseUrl: string
  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
  }
  public listShards = async (): Promise<Array<IShardRep>> => {
    console.log('Fetching the shards...')
    const url = this.baseUrl + '/v1/shard/'
    const res = await fetch(url)
    const responseBody = await res.json()
    console.log(responseBody)
    if (res.status > 299) {
      throw new Error(responseBody.message)
    }
    return responseBody
  }
}
export const initialShardList:Array<IShardRep> = [
  {name: "Shard 00",guid: "a0901489-b90d-4b6f-92ce-29d500461c51"},
  {name: "Shard 01",guid: "6379f6f8-7c1b-4666-b740-e55ea0f4a956"},
  {name: "Shard 02",guid: "e64dc5cd-4c6d-4b1e-ba0a-79ded98b53c9"},
  {name: "Shard 03",guid: "7760fdc8-dbc7-4630-ac38-43d56c073f14"},
  {name: "Shard 04",guid: "04ed2a39-4442-43ee-b6c3-298f12165dd4"},
  {name: "Shard 05",guid: "051c1103-ca7f-4d23-86d6-a99d8ab69b4f"},
  {name: "Shard 06",guid: "067c6566-3d68-4dd1-a4af-0192b6461994"},
  {name: "Shard 07",guid: "070a5051-2588-4e79-a843-8d8f5cea9733"},
  {name: "Shard 08",guid: "080cb42b-db09-40b3-8540-ae961049aefa"},
  {name: "Shard 09",guid: "09377db7-79fc-49b9-bb85-5ca59b4a9e88"}
]
