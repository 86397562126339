import * as React from 'react'
import {
  IFlowControlPolicy,
  IPolicyUpdateRequest,
  IPolicyDeletionRequest
} from '@local/interfaces'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Table, Head, Body, HeadingCell, Row } from '@toasttab/buffet-pui-table'
import { Tooltip } from '@toasttab/buffet-pui-tooltip'
import { PolicyRow, SkeletonRow } from '@local/flow-control-policy-row'
import { EmptyState } from '@toasttab/buffet-pui-empty-state'
import { WarningOutlineIcon } from '@toasttab/buffet-pui-icons'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { EditPolicyModal } from '@local/flow-control-modals'
import { useSentry } from 'banquet-runtime-modules'
import { DeletePolicyModal } from '@local/flow-control-modals/src/DeletePolicyModal'
import { useContext } from 'react'
import { FlowControlApiContext } from '@local/flow-control-client/src/FlowControlApiContext'
import {initialShardList, ShardBrainApiContext} from "@local/shard-brain-client";

export interface FlowControlPolicyTableProps {
  testId?: string | number
}

/**
 * FlowControlPolicyTable component
 * The table to display flow control policies.
 */

export function FlowControlPolicyTable({
  testId = 'flow-control-policy-table'
}: FlowControlPolicyTableProps) {
  const queryClient = useQueryClient()
  const { policyApi } = useContext(FlowControlApiContext)
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false)
  const [currentPolicy, setCurrentPolicy] = React.useState<IFlowControlPolicy>({
    target: '',
    policyType: '',
    defaultRestrictionPercentage: 0,
    targetedAttributes: { shards: {} },
    description: '',
    lastModified: '',
    createdAt: ''
  })
  const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()
  const { captureException } = useSentry()
  const { isLoading, data, isError, error } = useQuery({
    queryKey: ['fc-gateway'],
    queryFn: policyApi.getPolicies,
    onError: (err: Error) => {
      showErrorSnackBar(
        `An error has occured while fetching the policy list: ${err.message}`
      )
      captureException(new Error(`[Get policies]: Error: ${err.message}`))
    }
  })
  const { mutate: deletePolicy } = useMutation({
    mutationKey: 'delete-policy',
    mutationFn: async (policyRequest: IPolicyDeletionRequest) => {
      return policyApi.deletePolicy(policyRequest)
    },
    onSuccess: async () => {
      // This also refetches data in the background.
      showSuccessSnackBar('Policy deletion Successful.')
      await queryClient.invalidateQueries(['fc-gateway'])
      console.log('invalidated')
      console.log('refetched')
    },
    onError: (err: Error) => {
      showErrorSnackBar(
        `An error has occured while deleting the policy: ${err.message}`
      )
      captureException(new Error(`[Delete policy]: Error: ${err.message}`))
    }
  })
  const { mutate: editPolicy } = useMutation({
    mutationKey: 'edit-policy',
    mutationFn: async (policyRequest: IPolicyUpdateRequest) => {
      return policyApi.updatePolicy(policyRequest)
    },
    onSuccess: async () => {
      console.log('on success')
      // This also refetches data in the background.
      showSuccessSnackBar('Policy update successful.')
      await queryClient.invalidateQueries(['fc-gateway'])
      console.log('invalidated')
      console.log('refetched')
    },
    onError: (err: Error) => {
      showErrorSnackBar(
        `An error has occured while editing the policy: ${err.message}`
      )
      captureException(new Error(`[Edit policy]: Error: ${err.message}`))
    }
  })
  const { listShards } = useContext(ShardBrainApiContext)
  const {
    isLoading: isShardListLoading,
    data: shardList,
    isError: isShardListError,
    error: shardListError
  } = useQuery({
    queryKey: ['shards'],
    queryFn: listShards,
    initialData: ()=>initialShardList, // this data will be used before the shard list is loaded and if there is an error loading the shard list
    onError: (err: Error) => {
      console.log('error loading shards', err)
      showErrorSnackBar(
          `An error has occured while fetching the shard list: ${err.message}`
      )
      captureException(new Error(`[Get Shards]: Error: ${err.message}`))
    },
    refetchOnMount: true,
  })
  return (
    <div className='p-4 space-y-2 type-default' data-testid={testId}>
      <EditPolicyModal
        isOpen={isEditModalOpen}
        setIsOpen={setIsEditModalOpen}
        updatePolicy={async (policyRequest: IPolicyUpdateRequest) => {
          editPolicy(policyRequest)
        }}
        policy={currentPolicy!}
        shardList={shardList}
      />
      <DeletePolicyModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        deletePolicy={async (policyRequest: IPolicyDeletionRequest) => {
          deletePolicy(policyRequest)
        }}
        policy={currentPolicy!}
      />
      <Table className='table table-striped table-bordered'>
        <Head data-testid='policyTableHeader'>
          <Row>
            <HeadingCell>Target</HeadingCell>
            <HeadingCell>Description</HeadingCell>
            <HeadingCell>Policy type</HeadingCell>
            <HeadingCell>
              <Tooltip
                content={() =>
                  'Considered inactive when default restriction percentage and all the attributes restriction are set to 0%'
                }
              >
                Status
              </Tooltip>
            </HeadingCell>
            <HeadingCell>
              <Tooltip
                content={() =>
                  'The percentage of restaurants whose requests will all be restricted by attribute if specified. For ' +
                  'traffic not correlated to a specified attributes will get the `default` restrictions.'
                }
              >
                Aggregated restriction percentages
              </Tooltip>
            </HeadingCell>
            <HeadingCell>Created date</HeadingCell>
            <HeadingCell>Last modified</HeadingCell>
            <HeadingCell>Edit</HeadingCell>
          </Row>
        </Head>
        <Body data-testid='policyTableBody'>
          {isLoading ? (
            <SkeletonRow />
          ) : isError ? (
            <EmptyState icon={<WarningOutlineIcon />}>
              Sorry, we were unable to load this page. Please{' '}
              <a className='text-link' href='/'>
                reload this page
              </a>
              .<p className='font-mono'>{JSON.stringify(error?.message)}</p>
            </EmptyState>
          ) : (
            data?.map((p: IFlowControlPolicy) => (
              <PolicyRow
                key={p.target}
                editPolicy={() => {
                  setCurrentPolicy(p)
                  setIsEditModalOpen(true)
                }}
                deletePolicy={() => {
                  setCurrentPolicy(p)
                  setIsDeleteModalOpen(true)
                }}
                policy={p}
                shardList={shardList}
              />
            ))
          )}
        </Body>
      </Table>
    </div>
  )
}
