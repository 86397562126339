import * as React from 'react'
import { IFlowControlPolicy } from '@local/interfaces'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { DeleteIcon, EditIcon } from '@toasttab/buffet-pui-icons'
import { Row, Cell } from '@toasttab/buffet-pui-table'
import { Tooltip } from '@toasttab/buffet-pui-tooltip'
import classNames from 'classnames'
import {IShardRep} from "@local/shard-brain-client";
import {TargetedShardDetail} from "./TargetedShardDetail";

export interface PolicyRowProps {
  testId?: string | number
  policy: IFlowControlPolicy
  deletePolicy: () => void
  editPolicy: () => void
  shardList: Array<IShardRep> | undefined
}

function isPolicyInactive(
  defaultRestrictionPercentage: number,
  targetedAttributes: IFlowControlPolicy['targetedAttributes']
): boolean {
  if (
    defaultRestrictionPercentage === 0 &&
    (targetedAttributes === null || targetedAttributes.shards === null)
  )
    return true

  return (
    defaultRestrictionPercentage === 0 &&
    targetedAttributes !== null &&
    targetedAttributes.shards !== null &&
    Object.values(targetedAttributes.shards ?? {}).every(
      (shard) => shard.restrictionPercentage === 0
    )
  )
}

/**
 * PolicyRow component
 * Row to render a control policy
 */

export function PolicyRow({policy:{
  defaultRestrictionPercentage,
  targetedAttributes,
  target,
  description,
  policyType,
  createdAt,
  lastModified
},editPolicy,deletePolicy,shardList}: PolicyRowProps) {
  const inactive = isPolicyInactive(
    defaultRestrictionPercentage,
    targetedAttributes
  )

  return (
    <Row
      data-testid='policyRow'
      className={classNames({
        'text-disabled': inactive
      })}
    >
      <Cell>{target}</Cell>
      <Cell>{description}</Cell>
      <Cell>{policyType}</Cell>
      <Cell>{inactive ? 'Inactive' : 'Active'}</Cell>
      <Cell>
        <div className='flex flex-row justify-between'>
          <strong>Default : </strong>
          <span data-testid='defaultRestriction'>
            {defaultRestrictionPercentage} %
          </span>
        </div>
        {targetedAttributes &&
          targetedAttributes.shards &&
          Object.keys(targetedAttributes.shards).map((shard) =>
              <TargetedShardDetail
                  key={shard}
                  shardGuid={shard}
                  shardName={shardList?.find((s)=>s.guid==shard)?.name}
                  restrictionPercentage={targetedAttributes.shards[shard].restrictionPercentage} />)
        }
      </Cell>
      <Cell>{new Date(parseInt(createdAt)).toISOString()}</Cell>
      <Cell>{new Date(parseInt(lastModified)).toISOString()}</Cell>
      <Cell>
        <div className='flex flex-row'>
          <Tooltip content={() => 'Edit policy'}>
            <IconButton
              data-testid='editButton'
              icon={<EditIcon />}
              onClick={() => editPolicy()}
            />
          </Tooltip>
          <Tooltip
            content={() =>
              inactive
                ? 'Delete policy'
                : 'All restriction percentages must be set to 0% before deletion'
            }
          >
            <IconButton
              data-testid='deleteButton'
              disabled={!inactive}
              icon={<DeleteIcon />}
              onClick={async () => {
                await deletePolicy()
              }}
            />
          </Tooltip>
        </div>
      </Cell>
    </Row>
  )
}
