import * as React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { PolicyApi, SaltApi } from '@local/flow-control-client'
import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'
import { FlowControlPage } from '@local/flow-control-page'
import {
  getCurrentEnvironment,
  ToastEnvironment
} from '@toasttab/current-environment'
import { FlowControlApiContext } from '@local/flow-control-client/src/FlowControlApiContext'
import { ShardBrainApi, ShardBrainApiContext } from '@local/shard-brain-client'
export const flowControlBaseUrl = '/api/service/flow-control'
export const shardBrainBaseUrl = '/api/service/shard-brain'
export function getIrd(): string {
  switch (getCurrentEnvironment()) {
    case ToastEnvironment.PROD:
      return 'aws-332176809242-prod'
    case ToastEnvironment.PREPROD:
      return 'aws-620354051118-preproduction'
    default:
      return 'aws-620354051118-preproduction'
  }
}

export function App() {
  const ird = getIrd()
  const policyApi = new PolicyApi(flowControlBaseUrl, ird)
  const saltApi = new SaltApi(flowControlBaseUrl, ird)
  const shardBrainApi = new ShardBrainApi(shardBrainBaseUrl)
  const queryClient = new QueryClient({
    defaultOptions: {
      mutations: {},
      queries: {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchOnMount: false,
        refetchInterval: false,
        refetchIntervalInBackground: false
      }
    }
  })
  return (
    <QueryClientProvider client={queryClient}>
      <FlowControlApiContext.Provider value={{ policyApi, saltApi }}>
        <ShardBrainApiContext.Provider value={shardBrainApi}>
          <SnackBarProvider>
            <FlowControlPage ird={ird} />
          </SnackBarProvider>
        </ShardBrainApiContext.Provider>
      </FlowControlApiContext.Provider>
    </QueryClientProvider>
  )
}
