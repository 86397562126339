import {createContext} from "react";
import {IPolicyApi, PolicyApi} from "./PolicyApi";
import {ISaltApi, SaltApi} from "./SaltApi";
interface IFlowControlApis {
    policyApi:IPolicyApi
    saltApi:ISaltApi
}
export const FlowControlApiContext = createContext<IFlowControlApis>({
    policyApi:new PolicyApi('',''),
    saltApi:new SaltApi('','')})
