import * as React from 'react'
import { IPolicyCreationRequest } from '@local/interfaces'
import { useMutation, useQueryClient } from 'react-query'
import { AddIcon } from '@toasttab/buffet-pui-icons'
import {
  HeadingGroup,
  PageActions,
  PageHeader,
  Title,
  LayoutProvider,
  Page,
  Panel,
  PageBody
} from '@toasttab/buffet-pui-config-templates'
import { Button, LinkButton } from '@toasttab/buffet-pui-buttons'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { useSentry } from 'banquet-runtime-modules'
import { CreatePolicyModal } from '@local/flow-control-modals/src/CreatePolicyModal'
import { FlowControlPolicyTable } from '@local/flow-control-policy-table'
import { FlowControlSaltDisplay } from '@local/flow-control-salt-display'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import { BucketDistributionModal } from '@local/flow-control-modals'
import { useContext } from 'react'
import { FlowControlApiContext } from '@local/flow-control-client/src/FlowControlApiContext'

export interface FlowControlPageProps {
  testId?: string | number
  ird: string
}

/**
 * FlowControlPage component
 * Main container component for flow control application
 */
export function FlowControlPage({
  testId = 'FlowControlPage',
  ird
}: FlowControlPageProps) {
  const { policyApi, saltApi } = useContext(FlowControlApiContext)
  const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(false)
  const [isBucketModalOpen, setIsBucketModalOpen] = React.useState(false)
  const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()
  const queryClient = useQueryClient()
  const { captureException } = useSentry()
  const { mutate: createPolicy } = useMutation({
    mutationKey: 'create-policy',
    mutationFn: async (policy: IPolicyCreationRequest) => {
      return policyApi.createPolicy(policy)
    },
    onSuccess: async () => {
      console.log('on success')
      // This also refetches data in the background.
      showSuccessSnackBar('Policy creation successful.')
      await queryClient.invalidateQueries()
      console.log('invalidated')
      console.log('refetched')
    },
    onError: (err: Error) => {
      showErrorSnackBar(
        `An error has occured while creating the policy: ${err.message}`
      )
      captureException(new Error(`[Create policy]: Error: ${err.message}`))
    }
  })
  const { mutate: createSalt } = useMutation({
    mutationKey: 'create-salt',
    mutationFn: async () => {
      return saltApi.createSalt()
    },
    onSuccess: async () => {
      console.log('on success')
      // Invalidate and refetch data
      showSuccessSnackBar('Buckets redistributed successfully.')
      await queryClient.invalidateQueries(['fc-salt'])
      console.log('invalidated')
      console.log('refetched')
    },
    onError: (err: Error) => {
      showErrorSnackBar(
        `An error has occured when redistributing the buckets: ${err.message}`
      )
      captureException(new Error(`[Create salt]: Error: ${err.message}`))
    }
  })
  return (
    <LayoutProvider disableMaxWidth>
      <Page>
        <PageHeader>
          <HeadingGroup>
            <Title>Gateway Policies</Title>
          </HeadingGroup>
          <PageActions>
            <div className='flex items-end justify-end space-x-3 flex-grow'>
              <FlowControlSaltDisplay />
              <TextInput label='IRD' value={ird} readOnly={true}></TextInput>
              <Button
                onClick={() => setIsCreateModalOpen(true)}
                iconLeft={<AddIcon />}
                className='w-full sm:w-auto'
              >
                Create policy
              </Button>
              <Button
                onClick={() => setIsBucketModalOpen(true)}
                iconLeft={<AddIcon />}
                className='w-full sm:w-auto'
              >
                Redistribute buckets
              </Button>
            </div>
          </PageActions>
        </PageHeader>
        <PageBody>
          <Panel>
            <FlowControlPolicyTable />
          </Panel>
          <div className='p-4 space-y-2 type-default' data-testid={testId}>
            <CreatePolicyModal
              isOpen={isCreateModalOpen}
              setIsOpen={setIsCreateModalOpen}
              createPolicy={async (policy: IPolicyCreationRequest) =>
                createPolicy(policy)
              }
            />
            <BucketDistributionModal
              isOpen={isBucketModalOpen}
              setIsOpen={setIsBucketModalOpen}
              createSalt={async () => createSalt()}
            />
            <div className='flex justify-end'>
              <LinkButton href='https://github.com/toasttab/toast-flow-control/blob/main/SOP.md'>
                User Guide
              </LinkButton>
            </div>
          </div>
        </PageBody>
      </Page>
    </LayoutProvider>
  )
}
