import { useState } from 'react'
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '@toasttab/buffet-pui-modal'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import {
  AutoSuggestTextInputField,
  SubmitButton
} from '@toasttab/buffet-pui-forms'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import { Button, ButtonGroup } from '@toasttab/buffet-pui-buttons'
import * as React from 'react'
import { IPolicyCreationRequest } from '@local/interfaces'
import { Alert } from '@toasttab/buffet-pui-alerts'

export interface CreateModalProps {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  createPolicy: (policy: IPolicyCreationRequest) => Promise<void>
}

export function CreatePolicyModal({
  isOpen,
  setIsOpen,
  createPolicy
}: {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  createPolicy: (policy: IPolicyCreationRequest) => Promise<void>
}) {
  const allTargets = [
    'gateway-mobile',
    'gateway-nonmobile',
    'gateway-devices',
    'gateway-ec'
  ]
  const [filteredTargets, setFilteredTargets] = useState(allTargets.concat([]))
  return (
    <Modal
      testId='create-policy-modal'
      isOpen={isOpen}
      overflowBehavior='none'
      onRequestClose={() => setIsOpen(false)}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      <ModalHeader>
        <div data-testid='create-modal-header'>Create gateway policy</div>
      </ModalHeader>
      <Formik
        onSubmit={async (formik) => {
          await createPolicy({
            description: formik['flow-control-description'],
            target: formik['flow-control-target'],
            policyType: 'GATEWAY'
          })
          setIsOpen(false)
        }}
        initialValues={{
          'flow-control-target': 'gateway-mobile',
          'flow-control-description': ''
        }}
        validationSchema={Yup.object().shape({
          'flow-control-target': Yup.string()
            .matches(
              /^[a-zA-Z0-9-]+$/,
              'Alphanumeric Characters or dashes only.'
            )
            .max(64, 'Target must be at most 64 characters')
            .required(),
          'flow-control-description': Yup.string().required()
        })}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <ModalBody>
              <div className='my-1'>
                <div className='flex flex-row'>
                  <div className='mr-2'>
                    <AutoSuggestTextInputField
                      items={filteredTargets}
                      renderItem={(value: any) => <span>{value}</span>}
                      comboboxProps={{
                        items: filteredTargets,
                        onInputValueChange: ({ inputValue }) => {
                          setFilteredTargets(
                            allTargets.filter(
                              (item) =>
                                !inputValue ||
                                item
                                  .toLowerCase()
                                  .startsWith(inputValue.toLowerCase())
                            )
                          )
                        }
                      }}
                      openOnFocus={true}
                      name='flow-control-target'
                      label='Policy target'
                      onChange={(e) => formik.handleChange(e)}
                      onBlur={formik.handleBlur}
                      value={formik.values['flow-control-target']}
                      suffixVariant='iconButton'
                    />
                  </div>
                </div>
              </div>
              <div className='my-1'>
                <div className='flex flex-row'>
                  <div className='mr-2'>
                    <TextInput
                      name='flow-control-description'
                      label='Description'
                      onChange={(e) => formik.handleChange(e)}
                      onBlur={formik.handleBlur}
                      placeholder='Enter a description'
                      value={formik.values['flow-control-description']}
                    />
                  </div>
                </div>
              </div>
              <Alert className='mt-4'>
                Policies are created at 0% Restriction.
              </Alert>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  testId='create-cancel-button'
                  variant='link'
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </Button>
                <SubmitButton
                  disabled={!(formik.dirty && formik.isValid)}
                  testId='submit-button'
                />
              </ButtonGroup>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
