import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'
import { Row, Cell } from '@toasttab/buffet-pui-table'
import React from 'react'

export function SkeletonRow() {
  return (
    <Row>
      <Cell>
        <Skeleton className='w-full h-6' />
      </Cell>
      <Cell>
        <Skeleton className='w-full h-6' />
      </Cell>
      <Cell>
        <Skeleton className='w-full h-6' />
      </Cell>
      <Cell>
        <Skeleton className='w-full h-6' />
      </Cell>
      <Cell>
        <Skeleton className='w-full h-6' />
      </Cell>
    </Row>
  )
}
