import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '@toasttab/buffet-pui-modal'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import { SubmitButton } from '@toasttab/buffet-pui-forms'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { Button, ButtonGroup } from '@toasttab/buffet-pui-buttons'
import * as React from 'react'
import { IFlowControlPolicy, IPolicyDeletionRequest } from '@local/interfaces'

export interface DeletePolicyModalProps {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  policy: IFlowControlPolicy
  deletePolicy: (deleteRequest: IPolicyDeletionRequest) => Promise<void>
}

export function DeletePolicyModal({
  isOpen,
  setIsOpen,
  policy,
  deletePolicy
}: {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  policy: IFlowControlPolicy
  deletePolicy: (deleteRequest: IPolicyDeletionRequest) => Promise<void>
}) {
  return (
    <Modal
      testId='delete-policy-modal'
      isOpen={isOpen}
      overflowBehavior='none'
      onRequestClose={() => setIsOpen(false)}
      shouldCloseOnOverlayClick
    >
      <ModalHeader>
        <div data-testid='delete-modal-header'>Delete Gateway Policy</div>
      </ModalHeader>
      <Formik
        onSubmit={async () => {
          await deletePolicy({
            target: policy.target
          })
          setIsOpen(false)
        }}
        initialValues={{
          'flow-control-confirm': '',
          'flow-control-target': policy.target
        }}
        validationSchema={Yup.object().shape({
          'flow-control-target': Yup.string()
            .required()
            .test(
              'matches-target',
              'Target must match to prevent errors',
              (target) => target === policy.target
            ),
          'flow-control-confirm': Yup.string()
            .required('Policy target is a required field.')
            .test(
              'matches-name',
              'Policy target must be entered to confirm',
              (target) => target === policy.target
            )
        })}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <ModalBody>
              <div className='my-1'>
                <div className='flex flex-row'>
                  <div className='mr-2'>
                    <TextInput
                      name='flow-control-target'
                      disabled={true}
                      label='Policy target'
                      onChange={(e) => formik.handleChange(e)}
                      onBlur={formik.handleBlur}
                      value={formik.values['flow-control-target']}
                    />
                  </div>
                </div>
              </div>
              <Alert variant='warning' className='my-2'>
                Using Flow control has serious implications on the toast
                platform and our customers. Confirm the intended target below
              </Alert>
              <div className='flex flex-row'>
                <div className='mr-2'>
                  <TextInput
                    label='Confirm target'
                    name='flow-control-confirm'
                    onChange={(e) => formik.handleChange(e)}
                    onBlur={formik.handleBlur}
                    helperText={policy.target}
                    value={formik.values['flow-control-confirm']}
                  />
                  {!formik.isValid && (
                    <span style={{ color: 'red' }}>
                      {formik.errors['flow-control-confirm']}
                    </span>
                  )}
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  variant='link'
                  onClick={() => setIsOpen(false)}
                  testId='delete-cancel-button'
                >
                  Cancel
                </Button>
                <SubmitButton
                  disabled={!(formik.dirty && formik.isValid)}
                  testId='submit-button'
                />
              </ButtonGroup>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
