import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import { App } from './app/App'
import './index.css'

// @ts-ignore (We can ignore this error. React is provided at runtime.)
const reactLifecycles = banquetSingleSpaReact({
  singleSpaCssLifecycles,
  cssScope: 'data-flow-control',
  rootComponent: App,
  portalContainers: ['banquetPortalsContainer'],

  sentry: {
    publicKey: '2a98ee1a325e4ae6a62bd645f0a381d2@o37442',
    projectId: '1486171',
    releaseVersion: process.env.PKG_VERSION
  }
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'flow-control-spa'
