import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '@toasttab/buffet-pui-modal'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import { SubmitButton } from '@toasttab/buffet-pui-forms'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { Button, ButtonGroup } from '@toasttab/buffet-pui-buttons'
import * as React from 'react'

export interface BucketDistributionModalProps {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  createSalt: () => Promise<void>
}

/**
 * BucketDistributionModal component
 * Modal to confirm redistributing salt for buckets in hashing algorithm
 */
export function BucketDistributionModal({
  isOpen,
  setIsOpen,
  createSalt
}: {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  createSalt: () => Promise<void>
}) {
  return (
    <Modal
      testId='bucket-distribution-modal'
      isOpen={isOpen}
      overflowBehavior='none'
      onRequestClose={() => setIsOpen(false)}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      <ModalHeader>
        <div data-testid='bucket-distribution-modal-header'>
          Redistribute buckets
        </div>
      </ModalHeader>
      <Formik
        onSubmit={async () => {
          await createSalt()
          setIsOpen(false)
        }}
        initialValues={{
          'flow-control-confirm': ''
        }}
        validationSchema={Yup.object().shape({
          'flow-control-confirm': Yup.string()
            .required('confirm is a required field.')
            .test(
              'matches-name',
              'confirm must be entered to proceed.',
              (confirm: string | undefined) =>
                confirm?.toLowerCase() === 'confirm'
            )
        })}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <ModalBody>
              <Alert className='my-2'>
                Redistributing buckets will change which requests will be
                flow-controlled. Ensure you want to do this before proceeding.
              </Alert>
              <div className='flex flex-row'>
                <div className='mr-2'>
                  <TextInput
                    label='Confirm redistribution'
                    name='flow-control-confirm'
                    onChange={(e) => formik.handleChange(e)}
                    onBlur={formik.handleBlur}
                    value={formik.values['flow-control-confirm']}
                    placeholder='confirm'
                  />
                  {!formik.isValid && (
                    <span style={{ color: 'red' }}>
                      {formik.errors['flow-control-confirm']}
                    </span>
                  )}
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  variant='link'
                  onClick={() => setIsOpen(false)}
                  testId='bucket-cancel-button'
                >
                  Cancel
                </Button>
                <SubmitButton
                  disabled={!(formik.dirty && formik.isValid)}
                  testId='bucket-submit-button'
                />
              </ButtonGroup>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
