import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '@toasttab/buffet-pui-modal'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { NumberInput, TextInput } from '@toasttab/buffet-pui-text-input'
import { SubmitButton } from '@toasttab/buffet-pui-forms'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { Button, ButtonGroup } from '@toasttab/buffet-pui-buttons'
import * as React from 'react'
import { IFlowControlPolicy, IPolicyUpdateRequest } from '@local/interfaces'
import { AddIcon } from '@toasttab/buffet-pui-icons'
import { IShardRep } from '@local/shard-brain-client'
import { EditAttributeShardRow } from './EditAttributeShardRow'
import {
  addShardToFormik,
  getShardErrorMessage,
  getTargetedAttributesFromFormik,
  removeShardFromFormik, setShardInFormik,
  setShardRestrictionInFormik
} from "./formikPropUtils";

export interface EditModalProps {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  policy: IFlowControlPolicy
  updatePolicy: (updateRequest: IPolicyUpdateRequest) => Promise<void>
  shardList: Array<IShardRep> | undefined
}
export function EditPolicyModal({
  isOpen,
  setIsOpen,
  policy,
  updatePolicy,
  shardList
}: EditModalProps) {
  if (!isOpen) {
    return null
  }
  return (
    <Formik
      onSubmit={async (formik) => {
        console.log('onsubmit')
        // Floor the percentage to block edge cases like 10.0
        await updatePolicy({
          target: policy.target,
          description: formik['flow-control-description'],
          defaultRestrictionPercentage: Math.floor(
            formik['flow-control-default-percentage']
          ),
          targetedAttributes: getTargetedAttributesFromFormik(formik)
        })
        setIsOpen(false)
      }}
      initialValues={{
        'flow-control-default-percentage': policy.defaultRestrictionPercentage,
        'flow-control-description': policy.description,
        'flow-control-confirm': '',
        'flow-control-shards':
          policy.targetedAttributes === null ||
          policy.targetedAttributes.shards === null
            ? []
            : Object.entries(policy.targetedAttributes.shards).map(
                ([shard, { restrictionPercentage }], i) => ({
                  shard,
                  restriction: restrictionPercentage,
                  i
                })
              )
      }}
      validationSchema={Yup.object().shape({
        'flow-control-default-percentage': Yup.number()
          .required()
          .min(0, 'Percentage must be greater then 0')
          .max(100, 'Percentage must be less then 100')
          .typeError('Percentage must be an Integer')
          .integer(),
        'flow-control-shards': Yup.array().of(
          Yup.object().shape({
            restriction: Yup.number()
              .typeError('Percentage must be an Integer')
              .required('Percentage is required')
              .min(0, 'Percentage must be greater then 0')
              .max(100, 'Percentage must be less then 100')
              .integer('Percentage must be an Integer'),
            shard: Yup.string().required('Shard guid is required')
          })
        ),
        'flow-control-description': Yup.string().required(),
        'flow-control-confirm': Yup.string()
          .required('Policy target is a required field.')
          .test(
            'matches-name',
            'Policy target must be entered to confirm',
            (target) => target === policy.target
          )
      })}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <Modal
            testId='edit-policy-modal'
            isOpen={isOpen}
            overflowBehavior='body'
            onRequestClose={() => setIsOpen(false)}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
          >
            <ModalHeader>
              <div data-testid='edit-modal-header' className='gap-4'>
                Edit gateway policy
              </div>
            </ModalHeader>

            <ModalBody>
              <div className='my-1 gap-4'>
                <div className='flex flex-row gap-4'>
                  <div className='mr-2 gap-4'>
                    <TextInput
                      name='flow-control-target'
                      readOnly={true}
                      label='Policy target'
                      // onChange={(e) => formik.handleChange(e)}
                      // onBlur={formik.handleBlur}
                      value={policy.target}
                    />
                  </div>
                </div>
              </div>
              <div className='my-1 gap-4'>
                <div className='flex flex-row gap-4'>
                  <div className='mr-2 gap-4'>
                    <TextInput
                      name='flow-control-description'
                      label='Description'
                      onChange={(e) => formik.handleChange(e)}
                      onBlur={formik.handleBlur}
                      value={formik.values['flow-control-description']}
                    />
                  </div>
                </div>
              </div>
              <div className='my-1 gap-4'>
                <div className='flex flex-row gap-4'>
                  <div className='mr-2 gap-4'>
                    <NumberInput
                      suffix='%'
                      label='Default restriction percentage'
                      name='flow-control-default-percentage'
                      onChange={(e) =>
                        formik.setFieldValue(
                          'flow-control-default-percentage',
                          e.value,
                          true
                        )
                      }
                      onBlur={formik.handleBlur}
                      value={formik.values['flow-control-default-percentage']}
                    />
                    {!formik.isValid && (
                      <span style={{ color: 'red' }}>
                        {formik.errors['flow-control-default-percentage']}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <Alert variant='warning' className='my-2'>
                Using Flow control has serious implications on the toast
                platform and our customers. Confirm the intended target below
              </Alert>
              <div className='flex flex-row gap-4'>
                <div className='mr-2 gap-4'>
                  <TextInput
                    label='Confirm target'
                    name='flow-control-confirm'
                    onChange={(e) => formik.handleChange(e)}
                    onBlur={formik.handleBlur}
                    helperText={policy.target}
                    value={formik.values['flow-control-confirm']}
                  />
                  {!formik.isValid && (
                    <span style={{ color: 'red' }}>
                      {formik.errors['flow-control-confirm']}
                    </span>
                  )}
                </div>
              </div>
              <div className='flex flex-row-reverse gap-4'>
                <Button
                  iconLeft={<AddIcon />}
                  variant='link'
                  size='sm'
                  onClick={() => addShardToFormik(formik as any)}
                >
                  Shard
                </Button>
              </div>
              <div className='flex flex-col gap-4'>
                {formik.values['flow-control-shards'].map(
                  (
                    {
                      shard,
                      restriction
                    }: {
                      shard: string
                      restriction: number
                    },
                    i
                  ) => (
                    <EditAttributeShardRow
                      key={i}
                      shard={shard}
                      restriction={restriction}
                      setShard={(shard) =>
                        setShardInFormik(formik as any, i, shard || '')
                      }
                      setRestriction={(restriction) =>
                        setShardRestrictionInFormik(
                          formik as any,
                          i,
                          restriction
                        )
                      }
                      remove={() => removeShardFromFormik(formik as any, i)}
                      shardList={shardList}
                      showLabel={i < 1}
                      errorMessage={getShardErrorMessage(formik as any, i)}
                    />
                  )
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  variant='link'
                  onClick={() => setIsOpen(false)}
                  testId='edit-cancel-button'
                >
                  Cancel
                </Button>
                <SubmitButton
                  onClick={() => formik.handleSubmit()}
                  disabled={!(formik.dirty && formik.isValid)}
                  testId='submit-button'
                />
              </ButtonGroup>
            </ModalFooter>
          </Modal>
        </Form>
      )}
    </Formik>
  )
}
