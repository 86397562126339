import {FormikProps, FormikValues} from "formik";

export function getTargetedAttributesFromFormik(formik: FormikValues) {
    return {
        shards: formik['flow-control-shards'].reduce(
            (
                acc: { [shard: string]: number },
                v: { shard: string; restriction: number }
            ) => ({ ...acc, [v.shard]: { restrictionPercentage: v.restriction } }),
            {}
        )
    }
}
export function getShardErrorMessage(
    formik: FormikProps<FormikValues>,
    i: number
): string {
    const errorObject =
        ((formik.errors['flow-control-shards'] as any) || [])[i] || {}
    return Object.values(errorObject).join('; ')
}

export function addShardToFormik(formik: FormikProps<FormikValues>) {
    formik.setFieldValue(
        'flow-control-shards',
        [...formik.values['flow-control-shards'], { shard: '', restriction: 0 }],
        true
    )
}
export function removeShardFromFormik(formik: FormikProps<FormikValues>, i: number) {
    const shards = [...formik.values['flow-control-shards']] //shallow copy
    shards.splice(i, 1)
    formik.setFieldValue('flow-control-shards', shards, true)
}
export function setShardInFormik(
    formik: FormikProps<FormikValues>,
    i: number,
    shard: string
) {
    const shards = [...formik.values['flow-control-shards']] //shallow copy
    shards[i] = { ...shards[i], shard }
    formik.setFieldValue('flow-control-shards', shards, true)
}
export function setShardRestrictionInFormik(
    formik: FormikProps<FormikValues>,
    i: number,
    restriction: string
) {
    const shards = [...formik.values['flow-control-shards']] //shallow copy
    shards[i] = { ...shards[i], restriction }
    formik.setFieldValue('flow-control-shards', shards, true)
}
