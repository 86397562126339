import * as React from 'react'
import { IFlowControlSalt } from '@local/interfaces'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { useSentry } from 'banquet-runtime-modules'
import { useQuery } from 'react-query'
import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'
import { WarningOutlineIcon } from '@toasttab/buffet-pui-icons'
import { EmptyState } from '@toasttab/buffet-pui-empty-state'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import {useContext} from "react";
import {FlowControlApiContext} from "@local/flow-control-client/src/FlowControlApiContext";


/**
 * FlowControlSaltDisplay component
 * Display component for flow control salt
 */
export function FlowControlSaltDisplay() {
  const {saltApi} = useContext(FlowControlApiContext)
  const { showErrorSnackBar } = useSnackBar()
  const { captureException } = useSentry()
  const { isLoading, data, isError, error } = useQuery({
    queryKey: ['fc-salt'],
    queryFn: saltApi.getSalt,
    onError: (err: Error) => {
      showErrorSnackBar(
        `An error has occured while fetching the salt: ${err.message}`
      )
      captureException(new Error(`[Get Salt]: Error: ${err.message}`))
    }
  })
  if (isLoading) {
    return <Skeleton className='w-full h-10' />
  } else if (isError) {
    return (
      <EmptyState icon={<WarningOutlineIcon />}>
        Sorry, we were unable to load this page. Please{' '}
        <a className='text-link' href='/'>
          reload this page
        </a>
        .<p className='font-mono'>{JSON.stringify(error?.message)}</p>
      </EmptyState>
    )
  } else if (data?.lastModified) {
    return (
      <TextInput
        label='Buckets last distributed'
        value={new Date(parseInt(data.lastModified)).toISOString()}
        readOnly={true}
      ></TextInput>
    )
  } else {
    return (
      <TextInput
        label='Buckets last distributed'
        errorText='Please distribute the buckets.'
        invalid
        value='NEVER - PLEASE DO SO'
        readOnly={true}
      ></TextInput>
    )
  }
}
